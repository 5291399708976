<template>
  <header class="header">
    <div class="header__west-side">
      <router-link :to="{name: 'All'}">
        <img :src="require('@/assets/img/logo.svg')" alt="">
      </router-link>
    </div>

    <!-- <div v-if="getStatus">
      {{getStatus.status}}
    </div> -->

    <div class="header__east-side">
      <el-button class="header__btn" @click="logout" round>
        Log out
      </el-button>

      <div class="profile">
        <div class="profile__avatar" @click="setDropDown()">
          <img :src="getUserAvatar(getUser.email)" alt="user avatar">
        </div>
        <div v-if="Object.keys(getUser).length">
          <DropDown v-if="isOpen" :getStatus="getStatus" :getUser="getUser" @setDropDown="setDropDown"/>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import applyAvatar from '@/mixins/applyAvatar'

import DropDown from '@/components/ui/DropDown'

export default {
  name: 'Header',
  components: {
    DropDown,
  },
  data: () => ({
    isOpen: false,
  }),
  mixins: [applyAvatar],
  computed: {
    ...mapGetters(['getUser', 'getAvatars', 'getStatus']),
  },
  methods: {
    ...mapActions(['jwtRemove', 'fetchStatus']),
    async logout() {
      await this.jwtRemove();
      this.$router.push({name: 'LoginPage'});
    },
    setDropDown() {
      this.isOpen = !this.isOpen;
    }
  },
  async mounted() {
    await this.fetchStatus();
  }
}
</script>

<style lang="scss" scoped>
.center-block {
  position: fixed;
  top: 0;
  left: calc(50% - 200px);
  width: 400px;
}
.header { 
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 20px 24px; 

  &__east-side {
    display: flex;
    align-items: flex-end;
    gap: 40px;
  }

  &__btn {
    border-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 30px;
    color: #fff;
    background-color: #000;
    border-width: 1px;
    font-weight: 400;
    transition: all .3s ease;

    &:hover {
      background-color: #fff;
      color: #000;
      border-color: #fff;
    }
  }
}

.profile {
  position: relative;
  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    height: 50px;
    width: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    transition: all .5s ease;
    filter: grayscale(1);
  }
}
</style>