<template>
  <div class="select">

    <div popper-class="app-select" placeholder="Масштаб">
      <div v-for="(item, index) in status" :key="index" :value="item">

       <span class="content-info">
         <span class="desc">{{item.desc}}</span>

         <el-switch @change="handleClick(item.name, time = new Date().toISOString())"
            :value="switcher(getStatus[item.name])"
            active-color="#4FCD98" inactive-color="#000"
          >
          </el-switch>

         <el-time-picker v-model="item.val" @change="handleClick(item.name, item.val.toISOString())"
            size="mini" prefix-icon="false" editable format="HH:mm"
          >
         </el-time-picker>

         <i @click.stop="handleClick(item.name)" type="danger" class="el-icon-circle-close"></i>
       </span>

      </div>
      <div class="hr"></div>

      <router-link :to="{name:'PersonalMy', params: {login: getUser.login}}" class="_link-select">
        Мои навыки
      </router-link>
      <router-link :to="{name:'PersonalStats', params: {login: getUser.login}}" class="_link-select">
        Oбщее рабочее время
      </router-link>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DropDown',
  props: {
    getStatus : {
      type: Object,
      required: true
    },
    getUser: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    manualTime: new Date(24, 0),
    state: 0,
    elActive: true,
    status: [
      {
        name: 'entrance',
        desc: 'Пришел на работу',
        val: ''
      },
      {
        name: 'dinner',
        desc: 'На обеде',
        val: ''
      },
      {
        name: 'after_dinner',
        desc: 'Пришел с обеда',
        val: ''
      },
      {
        name: 'leave',
        desc: 'Ушел домой',
        val: ''
      },
    ],
  }),
  methods: {
    ...mapActions(['fetchTiming']),

    handleClick(val, time = '') {
      this.fetchTiming({val: val, time: time});
    },
    switcher(val) {
      return val ? true : false
    },
    searchTime() {
      for (const [key, value] of Object.entries(this.getStatus)) {
        this.status.find((el) => {
          if (el.name == key) {
            el.val = value || '';
          };
        });
      };
    },
  },
  async mounted() {
    this.searchTime();
    document.addEventListener('click', e => {
      e.preventDefault();
      if (!e.target.closest('.profile')) {
        this.$emit('setDropDown');
      }
    });
  },
  watch: {
    getStatus () {
      this.searchTime();
    },
  }
}
</script>

<style lang="scss">
  .profile {
    &:hover .profile__avatar {filter: grayscale(0); cursor: pointer;}
    & .select {
      position: absolute;
      top: 60px;
      left: -275px;
      background-color: #000;
      z-index: 11;
      border: 1px solid #464646;
      border-radius: 4px;
      padding: 15px 20px;
    }

    & .hr {
      height: 1px;
      background-color: #464646;
      margin-top: 10px;
    }

    & a._link-select {
      color: #fff;
      margin-top: 15px;
      text-decoration: none;
      display: flex;
      transition: all .3s ease-in;
      &:hover {
        color: #4ad4ff;
      }
    }
  }

  .content-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    margin: 5px;

    & .el-icon-circle-close{
      margin-left: 15px;
      &:before {
        cursor: pointer;
        font-size: 24px;
        color: #f00;
      }
    }

    & .desc {
      width: 140px;
      margin-right: auto;
      cursor: pointer;
    }

    & .el-switch {
      padding: 0 15px;
      & .el-switch__core {
        border-color: #464646 !important;
      }
    }

    & .el-date-editor.el-input {width: 60px;}
    & .el-input--mini .el-input__inner {
      padding: 0; text-align: center;
    }
  }

  
  .el-time-panel {
    width: 60px;
    &__footer {
      display: flex;
      justify-content: space-between;
    }
    &__btn {
      margin: 0;
      padding: 0;
    }
    &.el-popper[x-placement^=bottom] {
      margin-top: 5px;
    }
  }
</style>